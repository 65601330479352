/**
 * scripts.js
 * @author Cody Marcoux (Studio123)
 */

// Gather modules into one function
const initModules = () => {
    getAlert();
    getEditButton();
    initNews();
    initContactFormModal();
    initSliders();
    translateMailGo();
};

// Translate mailgo
const translateMailGo = () => {
    if ($("body").attr("data-language") == "fr") {
        setTimeout(function () {
            $("#mailgo a, #mailgo-tel a").html(function (e) {
                return $(this).html().replace("open in", "Ouvrir avec");
            });
            $("#mailgo a, #mailgo-tel a").html(function (e) {
                return $(this).html().replace("open", "Ouvrir");
            });
            $("#mailgo a, #mailgo-tel a").html(function (e) {
                return $(this).html().replace("call", "Ouvrir");
            });
            $("#mailgo a, #mailgo-tel a").html(function (e) {
                return $(this).html().replace("as ", "");
            });
            $("#mailgo a, #mailgo-tel a").html(function (e) {
                return $(this).html().replace("default", "avec défaut");
            });
            $("#mailgo a, #mailgo-tel a").html(function (e) {
                return $(this).html().replace("copy", "Copier");
            });
        }, 4000);
    }
};

const getLoggedInCookie = () => {
    // get logged in cookie
    const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("wp_user_logged_in"))
        ?.split("=")[1];

    const isUserLoggedIn = cookie && cookie === "1";

    if (isUserLoggedIn) {
        return true;
    } else {
        return false;
    }
};

const getEditButton = () => {
    if (getLoggedInCookie()) {
        let editHref = $(".main-container").data("edit-href");

        $("#edit-button").html(
            `<a data-barba-prevent target="_blank" href="${editHref}" class="edit-button"><i class="icon-edit"></i></a>`
        );
    }
};

// Close navigation function we can use during transitions
const closeNavigation = () => {
    if (menuButton.hasClass("is-active")) {
        if ($(window).width() <= 768) {
            toggleMobileNavigation(false);
        } else {
            toggleDesktopNavigation(false);
        }
    }
};

// Disable scroll restoration
if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
}

// Init all sections on load
initModules();

// Barba hooks
if (window.barba) {
    barba.hooks.before((data) => {
        closeNavigation();
        $("body").addClass("show-wait-cursor");
    });

    barba.hooks.beforeLeave((data) => {
        $("body").addClass("barba-animating");
    });

    barba.hooks.enter((data) => {
        $("body").removeClass("show-wait-cursor");

        if (typeof ga === "function") {
            ga("send", "pageview", location.pathname);
        }

        if (typeof fbq === "function") {
            fbq("track", "PageView", location.pathname);
        }

        initModules();
        initForms();
    });

    barba.hooks.afterEnter((data) => {
        initLandingPage();
        $("body").removeClass("barba-animating");

        $(".lang-link").attr(
            "href",
            $(".main-container").attr("data-wpml-permalink")
        );

        // copy over body classes
        var classes = $(".main-container").data("classes");
        if (classes != undefined && classes.length > 5) {
            $("body").attr("class", classes);
        }

        // update adminbar edit link
        if ($("#wpadminbar").length > 0) {
            $("#wpadminbar").find("a").attr("data-barba-prevent", "");
            var edit = $("#wpadminbar #wp-admin-bar-edit");
            if ($(".main-container").data("edit-href").length > 0) {
                edit.show();
                edit.find("a").text($(".main-container").data("edit-label"));
                edit.find("a").attr(
                    "href",
                    $(".main-container").data("edit-href")
                );
            } else {
                edit.hide();
            }
        }
    });
}

$(window).on("resize", () => {
    if ($(window).width() <= 768) {
        if (menuButton.hasClass("is-active")) {
            toggleMobileNavigation(false);
        }
    } else {
        if (menuButton.hasClass("is-active")) {
            toggleDesktopNavigation(false);
        }
    }
});

// Init Barba
if (window.barba) {
    barba.init({
        debug: false,
        prefetchIgnore: true,
        logLevel: 3,
        timeout: 5000,
        transitions: [fadeTransition, landingToSub],
        prevent: ({ el }) => {
            return el.href.indexOf(".pdf") > -1;
        },
    });
} else {
    console.warn(
        "Barba is not found. The site will work normally, but AJAX navigation is disabled."
    );
}
