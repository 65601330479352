/**
 * slider.js
 * @author Cody Marcoux (Studio123)
 * @description Slider class that we can re-instance throughout the site
 */

class Slider {
  constructor(element) {
    this.element = element;
    this.control = element.getElementsByClassName("scrubber")[0];
    this.stage = element.getElementsByClassName("stage")[0];
    this.innerStage = element.getElementsByClassName("inner-stage")[0];
    this.isTouchDevice = "ontouchstart" in window;

    this.initialize();
  }

  initialize() {
    if (this.stage.scrollWidth <= this.stage.clientWidth) {
      this.control.style.opacity = 0;
    } else {
      this.control.style.opacity = 1;
    }

    let scroll = anime({
      targets: this.stage,
      scrollLeft: [0, this.stage.scrollWidth - this.stage.clientWidth],
      easing: "linear",
      loop: true,
      autoplay: false,
    });

    // Add listener to range input
    this.control.addEventListener("input", () => {
      scroll.seek(scroll.duration * (this.control.value / 100));
    });

    if (this.isTouchDevice == 0) {
      scroll = anime({
        targets: this.stage,
        scrollLeft: [0, this.stage.scrollWidth - this.stage.clientWidth],
        easing: "linear",
        loop: true,
        autoplay: false,
      });
    }

    // Update anime on window resize
    window.addEventListener("resize", () => {
      if (this.stage.scrollWidth <= this.stage.clientWidth) {
        this.control.style.opacity = 0;
      } else {
        this.control.style.opacity = 1;
      }
    });

    // Update range input on scroll
    this.stage.addEventListener("scroll", () => {
      let width = this.stage.clientWidth;
      let scrollWidth = this.stage.scrollWidth - width;
      let leftOffset = this.innerStage.getBoundingClientRect().left;
      let percent = (leftOffset / scrollWidth) * -1 * 100;
      this.control.value = percent;
    });

    console.log(this.isTouchDevice);

    if (this.isTouchDevice == 0) {
      // Click and drag listeners
      let drag = {
        isDragging: false,
        startX: 0,
        scrollLeft: 0,
      };
      this.stage.addEventListener("mousedown", (e, i) => {
        drag.isDragging = true;
        elements = this;
        setTimeout(function () {
          elements.stage.classList.add("dragging");
        }, 300);
        drag.startX = e.pageX - this.stage.offsetLeft;
        drag.scrollLeft = this.stage.scrollLeft;
      });
      this.stage.addEventListener("mouseleave", () => {
        drag.isDragging = false;
        elements = this;
        setTimeout(function () {
          elements.stage.classList.remove("dragging");
        }, 100);
      });
      this.stage.addEventListener("mouseup", () => {
        drag.isDragging = false;
        elements = this;
        setTimeout(function () {
          elements.stage.classList.remove("dragging");
        }, 100);
      });
      this.stage.addEventListener("mousemove", (e) => {
        if (!drag.isDragging) return;
        e.preventDefault();
        const x = e.pageX - this.stage.offsetLeft;
        const walk = x - drag.startX; //scroll-fast
        this.stage.scrollLeft = drag.scrollLeft - walk;
      });
    }
  }
}
