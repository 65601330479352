/**
 * landingPage.js
 * @author Cody Marcoux (Studio123)
 */

const initLandingPage = () => {
  if ($('.section--landing_page').length) {
    let HorizontalScrollbar = window.Scrollbar;
    HorizontalScrollbar.use(HorizontalScrollPlugin, OverscrollPlugin);

    let sb = HorizontalScrollbar.init($('.box-container')[0], {
      damping: 0.25,
      overscrollEffect: 'bounce',
      alwaysShowTracks: true,
      renderByPixels: false
    });

    sb.addListener((status) => {
      if ($(window).width() >= 768) {
        hideContainer.seek(hideContainer.duration * (status.offset.x / 1000));
      }
    });

    $('a.box').on('click', function() {
      $(this).addClass('loading');
      boxLoader.play();
    });

    let boxStagger = anime({
      targets: '.box',
      easing: 'easeInOutCirc',
      duration: 400,
      delay: 550,
      translateY: (el, i) => {
        return i % 2 ? '-4rem' : '4rem';
      },
      begin: () => {
        $('.box').css('pointer-events', 'none');
      },
      complete: () => {
        $('.box').css('pointer-events', 'all');
      }
    })

    let fadeWhenReady = anime({
      targets: '.box-container',
      easing: 'linear',
      duration: 300,
      opacity: [0, 1]
    })

    let boxLoader = anime({
      targets: '.box .loader img',
      easing: 'easeInOutSine',
      duration: 2000,
      loop: true,
      autoplay: false,
      rotate: '2turn'
    })

    let hideContainer = anime({
      targets: '.section--landing_page .desktop .container',
      opacity: [1, 0],
      duration: 300,
      autoplay: false
    });
  }
}