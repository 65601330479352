/**
 * initSliders.js
 * @author Cody marcoux (Studio123)
 */

const initSliders = () => {
  let sliderElements =
    ".section--event_listing, .section--news_listing, .section--quick_links, .section--image_gallery, .section--video_listing";
  if ($(sliderElements).length) {
    $(sliderElements).each(function (i, e) {
      let slider = new Slider($(this).find(".slider")[0]);
      // Prevent links from being clicked on drag
      $(this)
        .find("a")
        .click(function (event) {
          let isTouchDevice = "ontouchstart" in window;
          if (isTouchDevice == false) {
            if ($(this).parents(".stage").hasClass("dragging")) {
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              return false;
            }
          }
        });
    });
  }
};
