/**
 * siteWideAlert.js
 * @author Cody Marcoux (Studio123)
 */

const setAlertGutter = () => {
    $(".gutter").css("height", $("#site-wide-alert").height());
};

window.addEventListener("resize", () => {
    setAlertGutter();
});

const dismissAlert = (alertId) => {
    let dismissedAlerts = sessionStorage.getItem("dismissed-alerts");

    if (!dismissedAlerts) {
        dismissedAlerts = [];
    } else {
        dismissedAlerts = JSON.parse(dismissedAlerts);
    }

    !dismissedAlerts.includes(alertId) && dismissedAlerts.push(alertId);

    sessionStorage.setItem("dismissed-alerts", JSON.stringify(dismissedAlerts));
};

// Check for alerts on load
const getAlert = () => {
    $.ajax({
        url: "/alerts/",
        type: "GET",
    }).done(function (data) {
        let currentLanguage = $("body").attr("data-language");
        let dismissedAlerts = sessionStorage.getItem("dismissed-alerts");

        dismissedAlerts ??
            (dismissedAlerts = JSON.parse(dismissedAlerts)) ??
            (dismissedAlerts = []);

        if (data.length > 0) {
            let alerts = $(data).find(".partial--site_wide_alert");
            let nonDismissedAlerts = [];

            alerts.each(function (index, alert) {
                let alertId = parseInt($(alert).attr("data-alert-id"));
                let alertLanguage = $(alert).attr("data-alert-language");

                if (
                    !dismissedAlerts.includes(alertId) &&
                    alertLanguage == currentLanguage
                ) {
                    nonDismissedAlerts.push($(alert));
                }
            });

            if (nonDismissedAlerts.length > 0) {
                $("#site-wide-alert").html(nonDismissedAlerts);

                setAlertGutter();

                $(".close-alert").on("click", function (e) {
                    e.preventDefault();

                    let alert = $(this).parents(".partial--site_wide_alert");

                    let alertId = alert.data("alert-id");

                    dismissAlert(alertId);

                    alert.fadeOut(200, () => {
                        setAlertGutter();
                    });
                });
            }
        }
    });
};
