/**
 * mobileMenu.js
 * @author Cody Marcoux (Studio123)
 */

let menuIsAnimating = false;
let subMenuIsAnimating = false;
let activeSubNavPanel = null;
let menuButton = $('.menu-button');
let mobileNav = $('nav.mobile');
let items = [];

// Toggle menu
menuButton.on('click', function() {
  if (menuIsAnimating === false) {
    if (!menuButton.hasClass('is-active')) {
      if ($(window).width() <= 768) {
        toggleMobileNavigation(true);
      }
    } else {
      if ($(window).width() <= 768) {
        toggleMobileNavigation(false);
      }
    }
  }
});

// Open subnav
$('.nav-parent a.parent-item').on('click', function(e) {
  // Prevent browser default
  e.preventDefault();
  // Prevent barba
  e.stopPropagation();
  e.stopImmediatePropagation();
  // Toggle nav panel
  let panel = $(this).parents('li').data('menu-id');
  if (activeSubNavPanel === null && subMenuIsAnimating === false) {
    toggleSubNavigation(panel, true);
  }
});

// Close subnav
$('.main-menu .back a').on('click', function(e) {
  // Prevent browser default
  e.preventDefault();
  // Prevent barba
  e.stopPropagation();
  e.stopImmediatePropagation();
  // Toggle nav panel
  let panel = $(this).parents('li').data('menu-id');
  if (activeSubNavPanel !== null && subMenuIsAnimating === false) {
    toggleSubNavigation(panel, false);
  }
});

const toggleMobileNavigation = (open) => {
  tl = anime.timeline({
    easing: 'easeInOutCirc',
    loop: false,
    duration: 425,
    begin: () => {
      menuIsAnimating = true;
      if (open === true) {
        mobileNav.css('opacity', 1);
        menuButton.addClass('is-active');
        mobileNav.addClass('is-active');
        $('body').addClass('is-locked');
      } else {
        menuButton.removeClass('is-active');
        $('body').removeClass('is-locked');
      }
    },
    complete: () => {
      menuIsAnimating = false;
      if (open === false) {
        mobileNav.css('opacity', 0);
        mobileNav.removeClass('is-active');
        if (activeSubNavPanel !== null) {
          toggleSubNavigation(activeSubNavPanel, false);
        }
      }
    },
    update: () => {
      // Force repaint on update due to iOS Safari bug.
      mobileNav.offsetWidth
    }
  });
  if (open === true) {
    tl
      .add({
        targets: mobileNav[0],
        clipPath: [
          'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
          'polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)'
        ],
        webkitClipPath: [
          'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
          'polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)'
        ],
      })
      .add({
        targets: 'span.parent',
        translateX: ['1rem', 0],
        opacity: [0, 1],
        delay: anime.stagger(40)
      }, '-=225');
  } else {
    if (activeSubNavPanel === null) {
      tl
        .add({
          targets: 'span.parent',
          translateX: [0, '-1rem'],
          opacity: [1, 0],
          delay: anime.stagger(40)
        })
        .add({
          targets: mobileNav[0],
          clipPath: [
            'polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)',
            'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
          ],
          webkitClipPath: [
            'polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)',
            'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
          ],
        }, '-=350');
    } else {
      tl.add({
          targets: 'ul.submenu[data-menu-id="' + activeSubNavPanel + '"] span',
          translateX: [0, '-1rem'],
          opacity: [1, 0],
          delay: anime.stagger(40)
        })
        .add({
          targets: mobileNav[0],
          clipPath: [
            'polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)',
            'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
          ],
          webkitClipPath: [
            'polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)',
            'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
          ],
        }, '-=350');
    }
  }
}

const toggleSubNavigation = (panel, open) => {
  let subMenu = $('ul.submenu[data-menu-id="' + panel + '"]');
  tl = anime.timeline({
    easing: 'easeInOutCirc',
    loop: false,
    duration: 400,
    begin: () => {
      subMenuIsAnimating = true;
      if (open === true) {
        activeSubNavPanel = panel;
        subMenu.addClass('is-active');
      } else {
        activeSubNavPanel = null;
        subMenu.removeClass('is-active');
      }
    },
    complete: () => {
      subMenuIsAnimating = false;
    }
  });
  if (open === true) {
    tl
      .add({
        targets: subMenu[0],
        translateX: [0, '-100%']
      }, '0')
      .add({
        targets: 'ul.submenu[data-menu-id="' + panel + '"] span',
        translateX: ['1rem', 0],
        opacity: [0, 1],
        delay: anime.stagger(40)
      }, '-=225')
  } else {
    tl
      .add({
        targets: 'ul.submenu[data-menu-id="' + panel + '"] span',
        translateX: [0, '1rem'],
        opacity: [1, 0],
        delay: anime.stagger(40)
      }, )
      .add({
        targets: subMenu[0],
        translateX: ['-100%', 0]
      }, '-=300')
  }
}