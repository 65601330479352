/**
 * landingToSub.js
 * @author Cody Marcoux (Studio123)
 * @description Landing Page to Sub-page
 */

// Transition
const landingToSub = {
  name: 'landing-to-sub',
  sync: false,
  from: {
    namespace: 'landing-index',
    custom: ({
      trigger
    }) => {
      // Make sure it's a box element and not a navigation link
      return trigger.classList && trigger.classList.contains('box');
    }
  },
  to: {
    namespace: 'page'
  },
  leave: (data) => {
    return new Promise((resolve) => {
      let outerWidth = ($('.box-container').outerWidth() / 2);
      let boxOffset = $(data.trigger).offset().left;
      let boxWidth = ($(data.trigger).outerWidth() / 2);
      let sb = Scrollbar.get($('.box-container')[0]);
      let easing = 'easeInOutQuart';
      let noImage = false;

      $(data.trigger).addClass('active').removeClass('loading');
      $('.box').not($(data.trigger)).addClass('inactive');

      if ($(data.trigger).hasClass('no-image')) {
        noImage = true;
      }

      let tl = anime.timeline({
        begin: () => {
          $('.box-container, .box').css('pointer-events', 'none');
          // $('body').css('overflow', 'hidden');
          sb.destroy();
        }
      });

      tl
        .add({
          targets: '.box.inactive, .section--landing_page .bg-element, .section--landing_page .container',
          easing: easing,
          duration: 300,
          opacity: [1, 0]
        }, '0')
        .add({
          targets: '.box',
          easing: easing,
          duration: 300,
          translateY: '0.5rem',
        }, '0')
        .add({
          targets: '.box-container .inner',
          easing: easing,
          duration: 300,
          translateX: ((outerWidth - boxOffset) - (boxWidth)),
          complete: () => {
            // Copy trigger element
            let elem = $(data.trigger)[0];
            // Move it to the .box-container so we can position it correctly
            $('.box-container').append(elem);
            // Delete inner track
            $('.box-container .inner').remove();
            // Position the box in the middle before we resize it
            $(data.trigger).css('transform', 'translate(-50%, -50%)');
          }
        }, '0')
        .add({
          targets: '.box-container',
          easing: easing,
          duration: 500,
          height: () => {
            if (noImage === true) {
              return getHeights(true, true);
            } else {
              return getHeights(true, false);
            }
          }
        }, '400')
        .add({
          targets: data.trigger,
          easing: easing,
          duration: 500,
          backgroundColor: '#f5f5f5',
          width: $(window).innerWidth(),
          height: () => {
            if (noImage === true) {
              return getHeights(false, true);
            } else {
              return getHeights(false, false);
            }
          },
          complete: () => {

            window.scrollTo(0, 0);

            data.current.container.remove();
            resolve();

          }
        }, '400')
    });
  },
  enter: (data) => {
    return new Promise((resolve) => {
      let easing = 'easeInOutQuart';
      let tl = anime.timeline();

      tl
        .add({
          targets: '.section--impact_area .breadcrumb, .section--impact_area h2',
          easing: easing,
          duration: 300,
          delay: anime.stagger(35),
          translateX: ['-1rem', 0],
          opacity: [0, 1],
          complete: () => {
            resolve();
          }
        })
        .add({
          targets: 'section:not(.section--impact_area)',
          easing: easing,
          duration: 300,
          opacity: [0, 1],
          complete: () => {
            // $('body').css('overflow', 'visible');
            resolve();
          }
        }, '300')
    });
  }
}

// Helper
const getHeights = (returnImpactOnly, noImage) => {
  let windowWidth = $(window).width();
  let boxHeight = {
    mobile: '25rem',
    tablet: '34rem',
    desktop: '34rem'
  }
  let impactHeight = {
    mobile: '34rem',
    tablet: '42rem',
    desktop: '62.5rem'
  }
  if (noImage === true) {
    impactHeight = {
      mobile: '22rem',
      tablet: '25rem',
      desktop: '30rem'
    }
  }
  if (!returnImpactOnly) {
    if (windowWidth >= 1088) {
      return [boxHeight.desktop, impactHeight.desktop];
    } else if (windowWidth <= 768) {
      return [boxHeight.mobile, impactHeight.mobile];
    } else {
      return [boxHeight.tablet, impactHeight.tablet];
    }
  } else {
    if (windowWidth >= 1088) {
      return impactHeight.desktop;
    } else if (windowWidth <= 768) {
      return impactHeight.mobile;
    } else {
      return impactHeight.tablet;
    }
  }
}
