/**
 * forms.js
 * @author Cody Marcoux (Studio123)
 */

const initForms = () => {
    if ($(".gform_wrapper").length) {
        $(".gform_wrapper").each(function (i, e) {
            // Re-evaluate GravityForms javascript
            let formScript = $(this).parents(".form").find("script").text();
            eval(formScript);

            // make sure display is set to block
            $(this).css("display", "block");
        });

        gform.addFilter(
            "gform_datepicker_options_pre_init",
            function (optionsObj, formId, fieldId) {
                // Apply to field 59 only
                if (fieldId == 59 && formId == 10) {
                    optionsObj.minDate = 0;
                    gformCalculateProductPrice = function () {};
                }
                return optionsObj;
            }
        );
    }
};
