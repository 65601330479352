/**
 * fade.js
 * @author Cody Marcoux (Studio123)
 * @description This is the default fallback transition.
 */

const fadeTransition = {
  name: 'fade-transition',
  sync: false,
  leave: (data) => {
    return new Promise((resolve) => {
      let fadeOut = anime({
        targets: data.current.container,
        opacity: [1, 0],
        easing: 'linear',
        duration: 300,
        complete: () => {
          data.current.container.remove();
          window.scrollTo(0, 0);
          resolve();
        }
      });
    });
  },
  enter: (data) => {
    return new Promise((resolve) => {
      let fadeIn = anime({
        targets: data.next.container,
        opacity: [0, 1],
        easing: 'linear',
        duration: 300,
        complete: () => {
          resolve();
        }
      });
    });
  }
}
