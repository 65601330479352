/**
 * personnelListing.js
 * @author Cody Marcoux (Studio123)
 */

const initContactFormModal = () => {
    $(".section--personnel_listing").each(function (e) {
        let contactButtons = $(this).find(".contact-button");
        let sectionIndex = $(this).data("section-index");

        const createModal = () => {
            const modalHtml = `<div class="modal fullscreen micromodal-slide" id="personnel-contact-modal" aria-hidden="true">
            <div class="modal__overlay" tabindex="-1">
              <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="personnel-contact-modal-title">
                <div class="modal__content">
                  <div id="personnel-contact-modal-content" class="container"></div>
                </div>
              </div>
            </div>
          </div>`;

            $("body").append(modalHtml);
        };

        const createSuccess = () => {
            const successSvg = `<svg class="contact-form-success" viewBox="0 0 800 444" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="800" height="444" fill="#F6F6F6"/>
            <path d="M452 237L390.503 201.469C386.789 199.322 382.211 199.322 378.497 201.469L317 237H452Z" fill="#6B4A8C" class="envelope-top" style="opacity: 0" />
            <path d="M317 237H452V306C452 308.209 450.209 310 448 310H321C318.791 310 317 308.209 317 306V237Z" fill="#6B4A8C" class="envelope-bottom" style="opacity: 0" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M389.128 268.069C385.555 269.708 381.445 269.708 377.872 268.069L304.375 234.364L305.625 231.637L379.123 265.343C381.902 266.617 385.098 266.617 387.877 265.343L461.375 231.637L462.625 234.364L389.128 268.069Z" fill="#F6F6F6" />
            <g class="contact-form-success__checkmark" style="opacity: 0">
            <circle cx="384" cy="187" r="33" fill="#12B087"/>
            <path d="M398.938 176.742C399.641 177.445 399.641 178.5 398.938 179.133L380.375 197.695C379.742 198.398 378.688 198.398 378.055 197.695L368.492 188.133C367.789 187.5 367.789 186.445 368.492 185.742C369.125 185.109 370.18 185.109 370.812 185.742L379.25 194.18L396.617 176.742C397.25 176.109 398.305 176.109 398.938 176.742Z" fill="#F6F6F6"/>
            </g>
            </svg>
            `;

            // if .contact-form-success doesn't exist, add it
            if (!$("#personnel-contact-modal .contact-form-success").length) {
                $(".heading, .body-text").remove();

                $(
                    "#personnel-contact-modal .gform_confirmation_wrapper"
                ).prepend(successSvg);
            }

            let timeline = anime.timeline({
                easing: "easeOutExpo",
                autoplay: true,
                duration: 350,
                delay: 200,
            });

            timeline
                .add({
                    targets: ".envelope-bottom",
                    opacity: [0, 1],
                })
                .add({
                    targets: ".envelope-top",
                    translateY: [50, 0],
                    opacity: [0, 1],
                    transformOrigin: ["50% 50% 0", "50% 50% 0"],
                    delay: 50,
                })
                .add({
                    targets: ".contact-form-success__checkmark",
                    transformOrigin: ["50% 50% 0", "50% 50% 0"],
                    opacity: [0, 1],
                    translateY: [40, 0],
                    delay: 100,
                });
        };

        contactButtons.on("click", function (e) {
            let personnelId = $(this).data("id");
            let pageId = $(this).data("page-id");
            let button = $(this);

            if (personnelId === undefined || personnelId === "") {
                console.error("Error:", "No ID attribute found.");
                return;
            }

            button.addClass("is-loading");
            createModal();

            $.ajax({
                url: "/wp-admin/admin-ajax.php",
                type: "POST",
                data: {
                    action: "get_contact_form",
                    personnel_id: personnelId,
                    page_id: pageId,
                    section_index: sectionIndex,
                },
                success: function (response) {
                    if (response.success === false) {
                        console.error("Error:", response.data.message);
                        button.removeClass("is-loading");
                        return;
                    }

                    button.removeClass("is-loading");

                    $("#personnel-contact-modal-content").html(response);

                    // FIX: remove action url from form so it will submit without 400 error
                    $("#personnel-contact-modal-content")
                        .find("form")
                        .attr("action", "");

                    // create new micromodal and add html response
                    let modal = MicroModal.show("personnel-contact-modal", {
                        onShow: (modal) => {
                            $("body").addClass("no-scroll");
                        },
                        onClose: (modal) => {
                            $("body").removeClass("no-scroll");
                            $("#personnel-contact-modal").remove();
                        },
                    });

                    // add event listener to form for success message
                    const formId = $("#personnel-contact-modal-content")
                        .find(".form-container")
                        .attr("data-form-id");

                    $(document).on(
                        "gform_confirmation_loaded",
                        function (event, formId) {
                            if (formId === formId) {
                                createSuccess();
                            }
                        }
                    );
                },
                error: function (error) {
                    console.error(error);
                    button.removeClass("is-loading");
                },
            });
        });
    });
};
