/**
 * horizontalScrolling.js
 * @author Cody Marcoux (Studio123)
 * @description Extends smooth-scrollbar to take all valid input and force scrolling on the x-axis.
 */

class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
  transformDelta(delta) {
    let x = delta.x;
    let y = delta.y;

    return {
      y: 0,
      x: Math.abs(x) > Math.abs(y) ? x : y
    };
  }
}

Object.defineProperty(HorizontalScrollPlugin, "pluginName", {
  value: 'horizontalScroll'
});