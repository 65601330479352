/**
 * desktopMenu.js
 * @author Cody Marcoux (Studio123)
 */

let menuIsAnimating = false;
let menuButton = $('.menu-button');
let desktopNav = $('nav.desktop');

// Toggle menu
menuButton.on('click', function() {
  if (menuIsAnimating === false) {
    if (!menuButton.hasClass('is-active')) {
      if ($(window).width() > 768) {
        toggleDesktopNavigation(true);
      }
    } else {
      if ($(window).width() > 768) {
        toggleDesktopNavigation(false);
      }
    }
  }
});

const toggleDesktopNavigation = (open) => {
  tl = anime.timeline({
    easing: 'easeInOutCirc',
    loop: false,
    duration: 425,
    begin: () => {
      menuIsAnimating = true;
      if (open === true) {
        menuButton.addClass('is-active');
        desktopNav.addClass('is-active');
        $('body').addClass('is-locked');
      } else {
        menuButton.removeClass('is-active');
        $('body').removeClass('is-locked');
      }
    },
    complete: () => {
      menuIsAnimating = false;
      if (open === false) {
        desktopNav.removeClass('is-active');
      }
    }
  });
  if (open === true) {
    tl.add({
      targets: desktopNav[0],
      clipPath: [
        'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
      ],
      webkitClipPath: [
        'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
      ],
    })
    $('.main ul.menu').each(function(i, e) {
      tl.add({
        targets: $(e).find('li')[0].getElementsByTagName("span"),
        translateX: ['1rem', 0],
        opacity: [0, 1],
        delay: anime.stagger(40)
      }, '0');
    });
    tl.add({
      targets: 'nav.desktop .footer',
      translateY: ['100%', 0]
    }, '-=275');
  } else {
    $('.main ul.menu').each(function(i, e) {
      tl.add({
        targets: $(e).find('li')[0].getElementsByTagName("span"),
        translateX: [0, '-1rem'],
        opacity: [1, 0],
        delay: anime.stagger(40),
      }, '0');
    });
    tl.add({
        targets: 'nav.desktop .footer',
        translateY: [0, '100%']
      }, '-=275')
      .add({
        targets: desktopNav[0],
        clipPath: [
          'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
          'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'
        ],
        webkitClipPath: [
          'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
          'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'
        ],
      }, '-=275')
  }
}